<template>
  <div id="user.user.index">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
export default {
    name: 'user.user.index',
    created() {
      this.$store.dispatch("fetchUserData")
    }
}
</script>

<style>

</style>